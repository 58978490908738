import PRIMARY from '../../static/images/managementApi.svg';
import SECONDARY from '../../static/images/dataApi.svg';
import TERTIARY from '../../static/images/connectionApi.svg';
import ROUTES from '../docs-config/src';

export const APIReferenceItems = [
  {
    title: 'Data APIs',
    description: 'A set of APIs for interacting with data inside a vault',
    image: SECONDARY,
    link: ROUTES.apiReference.record,
  },
  {
    title: 'Management APIs',
    description:
      'Leverage Admin APIs to build API integrations that manage Users, Accounts and Workspaces',
    image: PRIMARY,
    link: ROUTES.apiReference.management,
  },
  {
    title: 'Connection APIs',
    description: 'A set of APIs for managing skyflow connections',
    image: TERTIARY,
    link: ROUTES.apiReference.connection,
    hidden: true,
  },
];
