import React from 'react';
import { APIReferenceItems } from '../utils/APIReference';
import ReferencePage from '../components/common/ReferencePage';

const APIReference: React.FC = () => {
  return (
    <ReferencePage
      title="API Reference"
      description="Skyflow supports REST APIs to manage your account and vaults. Our APIs
        are expressed using OpenAPI Specification"
      caption="Select one to view the API documentation"
      items={APIReferenceItems}
    ></ReferencePage>
  );
};

export default APIReference;
